import React from 'react';
import '../styles/Footer.css';
import { FiInstagram, FiLinkedin } from "react-icons/fi";
import logo from '../assets/logo-blanco-negro.png'; 
import instagram from '../assets/instagram-black.png'; 
import linkedin from '../assets/linkedin-black.png'; 

const FooterLevels = () => {
  return (
    <footer className="footer">
      <div className="footer-row">
        <div>
          <p className="footer-title-black">
            Contact us and improve your investor level
            <span className="footer-title-white-bold">[Do not Wait]</span>
          </p>
        </div>
      </div>
      <div className="footer-row">
        <p className="footer-subtitle-black">
          whatever
        <span className="footer-title-white">presales@thebanc.io</span>
        </p>
      </div>
      <div className="footer-row">
        <div className="footer-col">
          <p className="footer-text-black">Follow us <a href="https://www.instagram.com/thebanc_io/" target="_blank"><img src={instagram} className="footer-icon"></img></a><a href="https://es.linkedin.com/company/the-banc" target="_blank"><img src={linkedin} className="footer-icon"></img></a>
          </p>
        </div>
        {/*<div className="footer-col footer-text-black">
          <p>Legal</p>
        </div>
        <div className="footer-col footer-text-black">
          <p>Investment Policy</p>
        </div>*/}
        <div className="footer-col footer-text-black footer-logo">
          <img className="logo" src={logo}/>
        </div>
      </div>
    </footer>
  );
};

export default FooterLevels;