import React, { useState } from 'react';
import '../styles/LoginForm.css';
import { Link, useNavigate } from 'react-router-dom';
import {  createUserWithEmailAndPassword  } from 'firebase/auth';
import { auth } from '../firebase';
import { database } from '../firebase';
import { ref, child, get, set } from "firebase/database";


function SignUpForm(){

  const [formText, setFormText] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const navigate = useNavigate();


  const handleSubmit = async () => {
    if (password === repeatPassword) {
      try {

        //setFormText("Estamos realizando labores de mantenimiento en el Área de Inversores con motivo de la incorporación del mercado secundario. Estará de nuevo accesible en las próximas semanas. Lamentamos las molestias.")
        setFormText("");
        const trimmedEmail = email.trim();
        const cleanEmail = email.trim().replaceAll(".", "");
        const trimmedPassword = password.trim();

        const dbRef = ref(database);
        get(child(dbRef, "emails")).then((snapshot) => {
          if (snapshot.val().hasOwnProperty(cleanEmail)) {
            console.log(snapshot.val());

            createUserWithEmailAndPassword(auth, trimmedEmail, trimmedPassword)
            .then((userCredential) => {
                console.log("Signed in")
                // Signed in
                const user = userCredential.user;
                console.log(user);
                console.log(snapshot.val());

                //Write in investors table
                set(ref(database, 'investors/' + user.uid), {
                  name: snapshot.val()[cleanEmail].name,
                  email: trimmedEmail,
                  bancs : snapshot.val()[cleanEmail].bancs,
                  investment: snapshot.val()[cleanEmail].investment,
                  type: snapshot.val()[cleanEmail].type,
                });


                navigate('/home');

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setFormText(errorMessage.replace('Firebase:',''))

            });

          } else {
            console.log("Este email no está dado de alta como inversor.");
            setFormText("Este email no está dado de alta como inversor. Si crees que se trata de un error ponte en contacto con nosotros.");
          }
        }).catch((error) => {
          console.error(error);
          setFormText(error);
        });

      } catch (error) {
        console.error('Error al crear el usuario:', error);
        setFormText(error)
      }
    } else {
      console.error('Las contraseñas no coinciden');
      setFormText('Las contraseñas no coinciden')
    }
  };

  return (
    <div className="login-container">
      <p className="login-title">CREAR CUENTA</p>
      <div className="form-group">
        <label className="form-label" htmlFor="email">[E-MAIL]</label>
        <input type="email"
        id="email"
        className="form-input"
        value={email}
        onChange={(e) => setEmail(e.target.value)} 
        />
      </div>
      <div className="form-group">
        <label className="form-label" htmlFor="password">[CONTRASEÑA]</label>
        <input type="password"
        id="password"
        className="form-input"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label className="form-label" htmlFor="password">[REPETIR CONTRASEÑA]</label>
        <input type="password"
        id="repeat-password"
        className="form-input"
        value={repeatPassword}
        onChange={(e) => setRepeatPassword(e.target.value)} 
        />
      </div>
      <span className="form-error">{formText}</span><br/>
      <button type="submit" onClick={handleSubmit}>[CREAR CUENTA]</button> <br/>
      <Link className="login-text" to="/">¿Ya estás registrado? Inicia sesión</Link>
    </div>
  );
}

export default SignUpForm;
