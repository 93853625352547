import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import '../styles/MarketPopup.css';
import Footer from '../components/Footer';
import arrow from '../assets/arrow.png'; 
import HeaderLevels from '../components/HeaderLevels';
import {  signInWithEmailAndPassword  } from 'firebase/auth';
import { auth } from '../firebase';
import { getAuth } from "firebase/auth";
import { database } from '../firebase';
import FooterLevels from '../components/FooterLevels';
import { ref, child, get, set, push } from "firebase/database";


function MarketPopup({onHide}) {

  const [formText, setFormText] = useState('');
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const [action, setAction] = useState('');

  const navigate = useNavigate();

  const auth = getAuth();
  const user = auth.currentUser;


  const handleSubmit = async () => {
    try {
      setFormText("");
      const marketUserRef = ref(database, 'market/' + user.uid);

      push(marketUserRef, {
        amount: amount,
        price: price,
        action: action,
        timestamp: Date.now(),
      }).then(() => {
        // Mensaje de éxito
        setFormText([
          "Hemos recibido tu solicitud. Nuestro equipo se ha puesto en marcha para encontrar la oferta que más encaje con la información que nos has enviado.",
          <br key="1" />,
          <br key="1" />,
          "Ten en cuenta que no es un proceso automático y que para poder finalizarse debemos encontrar un inversor con el que realizar el intercambio."
        ]);
        // Opcionalmente, redirige o realiza alguna acción después del éxito
        // navigate('/ruta-de-exito');
      }).catch((error) => {
        // En caso de error con la operación push
        console.error('Error al enviar solicitud:', error);
        setFormText("Error al enviar la solicitud. Por favor, intenta de nuevo.");
      });

    } catch (error) {
      console.error('Error al enviar solicitud:', error);
      // Mensaje de error genérico para otros errores
      setFormText("Ocurrió un error inesperado. Por favor, intenta de nuevo.");
    }
  };


  return (
    <div>
      <HeaderLevels onHide={onHide}/>
      <div className="login-container">
        <p className="login-title">¿CUÁNTOS BANC's QUIERES VENDER Y A QUÉ PRECIO TE GUSTARÍA HACERLO?</p>
        <div className="form-group">
          <label className="form-label" htmlFor="amount">[NÚMERO DE BANC'S]</label>
          <input type="number" id="amount"
          className="form-input"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}  />
        </div>
        <div className="form-group">
          <label className="form-label" htmlFor="price">[PRECIO DE VENTA UNITARIO] €</label>
          <input type="number" id="price"
          className="form-input"
          value={price}
          onChange={(e) => setPrice(e.target.value)} />
        </div>
        <div className="radio-group">
          <label className="form-label" htmlFor="action">[ACCIÓN]</label>
        </div>
        <div className="radio-group">
          <input type="radio" id="buy" name="action" value="buy" onClick={(e) => setAction(e.target.value)}/>
          <label className="form-input-radio" for="buy">Comprar</label>
          <input type="radio" id="sell" name="action" value="sell" onClick={(e) => setAction(e.target.value)}/>
          <label className="form-input-radio" for="sell">Vender</label>
        </div>
        
        {formText !== "" && (
          <div className="form-feedback-box">
            <span className="form-feedback">{formText}</span>
          </div>
        )}
        <br/><br/>
        <button type="submit" onClick={handleSubmit}>[ENVIAR SOLICITUD]</button> <br/>
        
      </div>
      <FooterLevels />
    </div>
  );
}

export default MarketPopup;