import React from 'react';
import '../styles/Levels.css';
import Footer from '../components/Footer';
import HeaderLevels from '../components/HeaderLevels';
import FooterLevels from '../components/FooterLevels';
import { FiArrowRight } from "react-icons/fi";
import arrow from '../assets/arrow.png'; 


function Levels({onHide}) {
  return (
    <div>
      <HeaderLevels onHide={onHide}/>
      <div className="three-columns-container">
        <div className="column-padding-levels">
          <div className="white-label">[STANDARD]* <span className="investor-label-black">INVESTOR</span></div>
          <p className="levels-title">1-3 BANC's</p>
          <ul>
            <li><img src={arrow} className="arrow-red"/> Acceso a la plataforma para inversores con los datos básicos.</li>
            <li><img src={arrow} className="arrow-red"/> Invitación a los eventos generales con precios especiales</li>
            <li><img src={arrow} className="arrow-red"/> Acceso a las rondas de financiación de las Startups de BANC</li>
          </ul>
        </div>
        <div className="column-padding-levels">
          <div className="levels-top-content">
            <div className="red-label">[GOVERNANCE]* <span className="investor-label-black">INVESTOR</span></div>
            <p className="levels-title">4-9 BANC's</p>
            <ul>
              <li><img src={arrow} className="arrow-red"/> Acceso a reportes internos de BANC sobre las Startups analizadas</li>
              <li><img src={arrow} className="arrow-red"/> Votación de Startups en la plataforma y en los Eventos</li>
              <li><img src={arrow} className="arrow-red"/> Ser advisor de las Startups de BANC o acudir al comité de Pre-Selección bajo previo acuerdo</li>
            </ul>
          </div>
          <p className="small-red-text">*Beneficios de STANDARD incluidos</p>
        </div>
        <div className="column-padding-levels">
          <div className="levels-top-content">
            <div className="black-label">[PREMIUM]* <span className="investor-label">INVESTOR</span></div>
            <p className="levels-title">+10 BANC's</p>
            <ul>
              <li><img src={arrow} className="arrow-red"/> Invitación a eventos especiales</li>
              <li><img src={arrow} className="arrow-red"/> Acceso anticipado a las rondas de las Startups de BANC y posibilidad de coinvertir</li>
              <li><img src={arrow} className="arrow-red"/> Capacidad de convertirse en Embajador de BANC</li>
              <li><img src={arrow} className="arrow-red"/> Acudir a los comités de Selección bajo previo acuerdo</li>
            </ul>
          </div>
          <p className="small-red-text">*Beneficios de GOVERNANCE incluidos</p>
        </div>
      </div>
      <FooterLevels />
    </div>
  );
}

export default Levels;
