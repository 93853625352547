import React, { useState, useEffect } from 'react';
import '../styles/InfoBox.css';
import { database } from '../firebase';
import { ref, child, get } from "firebase/database";
import { useNavigate } from 'react-router-dom';
import MarketActions from "../components/MarketActions";
import { getAuth } from "firebase/auth";

function InfoBox({ onClick, onClickMarket }) {
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [bancs, setBancs] = useState('');
  const [investment, setInvestment] = useState('');
  const [currentValue, setCurrentValue] = useState('');
  const [showMarketActions, setShowMarketActions] = useState(false);


  useEffect(() => {
    if (!user) {
      navigate('/');
    } else {
      const userProfileRef = ref(database, `investors/${user.uid}`);
      get(userProfileRef).then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.val();
          setName(userData.name);
          setType(userData.type);
          setBancs(userData.bancs);
          setInvestment(userData.investment.toLocaleString('es-ES'));
          let userSignature = false;
          if(userData.signature !== null && userData.signature !== undefined){
            userSignature = userData.signature
          }
          setShowMarketActions(userSignature)

          const bancValueRef = ref(database, "config/banc_value");
          get(bancValueRef).then((snapshot1) => {
            if (snapshot1.exists()) {
              setCurrentValue((userData.bancs * snapshot1.val()).toLocaleString('es-ES'));
            }
          }).catch((error) => {
            console.error(error);
          });
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }
  }, [navigate, user]);

  return (
    <div className="info-box">
      <div className="info-box-top" onClick={onClick}>
        <div className="name">{name}</div>
        <div className="premium-investor">{type} <span className="investor-label">INVESTOR</span></div>
      </div>
      <div className="info-box-bottom">
        <div className="white-box">
          <div>BANC's</div>
          <div className="box-text">{bancs}</div>
        </div>
        <div className="black-box">
          <div>INVERSIÓN</div>
          <div className="box-text">€{investment}</div>
        </div>
        <div className="red-box">
          <div>VALOR ACTUAL</div>
          <div className="box-text">€{currentValue}</div>
        </div>
      </div>
      {showMarketActions && (
        <MarketActions onClick={onClickMarket} />
      )}
    </div>
  );
}

export default InfoBox;

