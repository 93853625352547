import React, { useState, useEffect } from 'react';
import '../styles/StartupComponent.css';
import { FiMapPin, FiInstagram, FiLinkedin, FiGlobe, FiChevronLeft } from "react-icons/fi";
import { Link, useParams } from 'react-router-dom';
import { database } from '../firebase';
import NewsTable from '../components/NewsTable';
import { ref, child, get } from "firebase/database";
import { FiLink } from "react-icons/fi";
import { getAuth } from "firebase/auth";


function StartupComponent() {
  const { id } = useParams();
  const [logo, setLogo] = useState('');
  const [sector, setSector] = useState('');
  const [valuation, setValuation] = useState('');
  const [equity, setEquity] = useState('');
  const [bancValue, setBancValue] = useState('');
  const [creationYear, setCreationYear] = useState('');
  const [location, setLocation] = useState('');
  const [web, setWeb] = useState('');
  const [instagram, setInstagram] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [description, setDescription] = useState('');
  const [team, setTeam] = useState([]);
  const [finance, setFinance] = useState([]);
  const [documents, setDocuments] = useState([]);

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {

    const userProfileRef = ref(database, `investors/${user.uid}`);
    get(userProfileRef).then((snapshot) => {
      if (snapshot.exists()) {
        const type = snapshot.val().type;
        const dbRef = ref(database, `startups/${id}`);
        get(dbRef).then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setLogo(data.logo);
            setSector(data.sector);
            setValuation(data.valuation.toLocaleString('es-ES'));
            setEquity(data.equity.toLocaleString('es-ES'));
            setBancValue((data.equity * 0.01 * data.valuation).toLocaleString('es-ES'));
            setCreationYear(data.creation_year);
            setLocation(data.location);
            setWeb(data.web);
            setInstagram(data.instagram);
            setLinkedin(data.linkedin);
            setDescription(data.description);
            setTeam(data.team || []);
            setFinance(data.finance || []);
            if(type === "[GOVERNANCE]*" || type === "[PREMIUM]*"){
              setDocuments(data.documents || []);
            }else{
              setDocuments([]);
            }
          } else {
            console.log("No data available");
          }
        }).catch((error) => {
          console.error(error);
        });
      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });

  }, [id]);

  return (
    <>
      <div className="arrow-row">
        <Link to="/portfolio" className="arrow-link">
          <FiChevronLeft />
        </Link>
        <img src={logo} alt="Logo" className="startup-logo" />
        <Link to="/" className="arrow-link" style={{ opacity: 0 }}>
          <i className="fas fa-arrow-left"></i>
        </Link>
      </div>
      <div className="container">
        <div className="row">
          <div className="red-box round-corners">
            <div>SECTOR</div>
            <div className="box-text">{sector}</div>
          </div>
          <div className="black-box round-corners">
            <div>VALORACIÓN</div>
            <div className="box-text">€{valuation}</div>
          </div>
          <div className="white-box round-corners">
            <div>EQUITY BANC</div>
            <div className="box-text">%{equity}</div>
          </div>
          <div className="white-box round-corners">
            <div>VALOR BANC</div>
            <div className="box-text">€{bancValue}</div>
          </div>
        </div>

        <div className="row text-row">
          <div className="box-width">
            <p className="startup-title">AÑO CREACIÓN</p>
            <p>{creationYear}</p>
          </div>
          <div className="box-width">
            <p className="startup-title">UBICACIÓN <FiMapPin /></p>
            <p>{location}</p>
          </div>
          <div className="box-width">
          </div>
          <div className="box-width icons-column">
            <a href={web} target="_blank" rel="noopener noreferrer"><FiGlobe className="startup-icon" /></a>
            <a href={linkedin} target="_blank" rel="noopener noreferrer"><FiLinkedin className="startup-icon" /></a>
            <a href={instagram} target="_blank" rel="noopener noreferrer"><FiInstagram className="startup-icon" /></a>
          </div>
        </div>

        <p className="text-row startup-title">DESCRIPCIÓN</p>
        <div className="long-text-box">{description}</div>

        <p className="text-row startup-title">EQUIPO DIRECTIVO</p>
        {team.map((item, index) => (
          <div key={index} className="team-row">
            <div className="column team-name">{item.name}</div>
            <div className="column team-role">{item.role}</div>
            <div className="column team-linkedin">
              <a href={item.linkedin} target="_blank" rel="noopener noreferrer"><FiLinkedin className="team-icon" /></a>
            </div>
          </div>
        ))}

        <p className="text-row startup-title">ROADMAP FINANZAS</p>
        <div className="scroll-container">
          {finance.map((item, index) => (
            <div key={index} className="team-row">
              <div className="column team-black">{item.year}</div>
              <div className="column team-red">{item.valuation}</div>
              <div className="column team-white">{item.raised}</div>
              <div className="column team-black">{item.banc}</div>
              <div className="column team-black-last">{item.equity}</div>
            </div>
          ))}
        </div>

        {documents.length > 0 && (
          <>
            <p className="text-row startup-title">DOCUMENTOS RELEVANTES</p>
            <div className="scroll-container">
              {documents.map((item, index) => (
                <div key={index} className="documents-row">
                  <div className="column team-black">{item.name}</div>
                  <a href={item.link} target="_blank" rel="noopener noreferrer"><FiLink className="news-table-icon"></FiLink></a>
                </div>
              ))}
            </div>
          </>
        )}


        <p className="text-row startup-title">NEWS</p>
        <NewsTable />
      </div>
    </>
  );
}

export default StartupComponent;
