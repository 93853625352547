import React, { useState, useEffect } from 'react';
import '../styles/NewsGrid.css';
import { Link } from 'react-router-dom';
import { database } from '../firebase';
import { ref, child, get } from "firebase/database";
import { FiLink } from "react-icons/fi";

const NewsGrid = () => {
  const [boxes, setBoxes] = useState([]);
  const [boxes1, setBoxes1] = useState([]);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const dbRef = ref(database, "news");
    get(dbRef).then((snapshot) => {
      if (snapshot.exists()) {
        const newsItems = Object.values(snapshot.val());
        setItems(newsItems);

        try {
          setBoxes(newsItems.slice(0, 3));
        } catch (error) {
          console.log("not enough news");
        }

        try {
          setBoxes1(newsItems.slice(3, 6));
        } catch (error) {
          console.log("not enough news 1");
        }
      }
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <div>
      <div className="title-container">
        <p className="news-title">Noticias de BANC</p>
      </div>
      <div className="grid-container">
        {boxes.map((box, index) => (
          <Link key={`box-${index}`} to={box.link} target="_blank" className="grid-item" style={{ backgroundImage: `url(${box.src})` }}>
            <div className="grid-item-center">
              <span className="grid-title">{box.title}</span>
            </div>
            <div className="grid-item-bottom">
              <span className="grid-text">{box.type}</span>
            </div>
          </Link>
        ))}
        {boxes1.map((box, index) => (
          <Link key={`box1-${index}`} to={box.link} target="_blank" className="grid-item" style={{ backgroundImage: `url(${box.src})` }}>
            <div className="grid-item-center">
              <span className="grid-title">{box.title}</span>
            </div>
            <div className="grid-item-bottom">
              <span className="grid-text">{box.type}</span>
            </div>
          </Link>
        ))}
      </div>
      <div className="title-container">
        <p className="news-title">Todas las noticias</p>
      </div>
      <div className="overflow-x">
        <div className="six-columns-row">
          <div className="column"><p className="news-table-header">STARTUP</p></div>
          <div className="column"><p className="news-table-header">TYPE</p></div>
          <div className="column"><p className="news-table-header">DATE</p></div>
          <div className="column"><p className="news-table-header">AUTHOR</p></div>
          <div className="column"></div>
          <div className="column"></div>
        </div>
        <div>
          {items.map((item, index) => (
            <div key={`item-${index}`} className="six-columns-row">
              <div className="column"><img src={item.logo} alt="Logotipo" className="logo" /></div>
              <div className="column"><p className="news-table-cell">{item.type}</p></div>
              <div className="column"><p className="news-table-cell">{item.date}</p></div>
              <div className="column"><p className="news-table-cell">{item.author}</p></div>
              <div className="column"><p className="news-table-text">{item.description}</p></div>
              <div className="column news-table-icon-column">
                <a href={item.link} target="_blank" rel="noopener noreferrer"><FiLink className="news-table-icon"></FiLink></a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsGrid;
