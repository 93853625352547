import React, { useState, useEffect } from 'react';
import '../App.css';
import Header from "../components/Header";
import InfoBox from "../components/InfoBox";
import ThreeColumnGrid from "../components/ThreeColumnGrid";
import FooterPortfolio from '../components/FooterPortfolio';
import { getAuth } from "firebase/auth";
import { Link, useNavigate } from 'react-router-dom';



function PortfolioPage() {

  const navigate = useNavigate();

  useEffect(() => {

    const auth = getAuth();
    const user = auth.currentUser;

    // Navega a LoginPage si no hay usuario autenticado
    if (!user) {
      navigate('/');
    }
 
  }, [navigate]);

  
  return (
    <div className="App">
      <ThreeColumnGrid />
      <FooterPortfolio />
      {/* Resto de tu aplicación */}
    </div>
  );
   
}

export default PortfolioPage;