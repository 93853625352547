import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from "./components/Header";
import InfoBox from "./components/InfoBox";
import ImageSlider from "./components/ImageSlider";
import Footer from './components/Footer';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import HomePage from './pages/HomePage';
import PortfolioPage from './pages/PortfolioPage';
import StartupPage from './pages/StartupPage';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';


function App() {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/portfolio" element={<PortfolioPage />} />
            <Route path="/startup/:id" element={<StartupPage />} />
          </Routes>
        </div>
      </Router>
    </React.Suspense>
  );
}

export default App;


