import React from 'react';
import '../styles/Footer.css';
import { FiInstagram, FiLinkedin } from "react-icons/fi";
import logo from '../assets/logo-negro-rosa.png'; 
import instagram from '../assets/instagram-black.png'; 
import linkedin from '../assets/linkedin-black.png'; 


const FooterStartup = () => {
  return (
    <footer className="footer-startup">
      <div className="footer-row">
        <div>
          <p className="footer-title-black-startup">
            Selecting the best projects for you
            <span className="footer-title-red-bold-startup">[investors]</span>
          </p>
        </div>
      </div>
      <div className="footer-row">
        <p className="footer-subtitle-black-startup">
          whatever
          <span className="footer-title-red-startup">presales@thebanc.io</span>
        </p>
      </div>
      <div className="footer-row">
        <div className="footer-col">
          <p className="footer-text-black-startup">Follow us <a href="https://www.instagram.com/thebanc_io/" target="_blank"><img src={instagram} className="footer-icon"></img></a><a href="https://es.linkedin.com/company/the-banc" target="_blank"><img src={linkedin} className="footer-icon"></img></a>
          </p>
        </div>
        {/*<div className="footer-col footer-text-black-startup">
          <p>Legal</p>
        </div>
        <div className="footer-col footer-text-black-startup">
          {/*<p>Investment Policy</p>
        </div>*/}
        <div className="footer-col footer-text-black-startup footer-logo">
          <img className="logo" src={logo}/>
        </div>
      </div>
    </footer>
  );
};

export default FooterStartup;