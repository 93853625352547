import React, { useState, useEffect } from 'react';
import '../App.css';
import Header from "../components/Header";
import StartupComponent from "../components/StartupComponent";
import FooterStartup from '../components/FooterStartup';
import { getAuth } from "firebase/auth";
import { Link, useNavigate } from 'react-router-dom';


function StartupPage() {

  const navigate = useNavigate();

  useEffect(() => {

    const auth = getAuth();
    const user = auth.currentUser;

    // Navega a LoginPage si no hay usuario autenticado
    if (!user) {
      navigate('/');
    }
 
  }, [navigate]);

  return (
    <div className="App">
      <StartupComponent/>
      <FooterStartup />
      {/* Resto de tu aplicación */}
    </div>
  );
}

export default StartupPage;