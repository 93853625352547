import React, { useState, useEffect } from 'react';
import '../App.css';
import Header from "../components/Header";
import InfoBox from "../components/InfoBox";
import NewsGrid from "../components/NewsGrid";
import ImageSlider from "../components/ImageSlider";
import Footer from '../components/Footer';
import Levels from '../components/Levels';
import MarketPopup from '../components/MarketPopup';
import { getAuth } from "firebase/auth";
import { Link, useNavigate } from 'react-router-dom';


function HomePage() {
  const [showLevels, setShowLevels] = useState(false);
  const [showMarket, setShowMarket] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {

    const auth = getAuth();
    const user = auth.currentUser;

    // Navega a LoginPage si no hay usuario autenticado
    if (!user) {
      navigate('/');
    }
 
  }, [navigate]);

  return (
    <div className="page-content">
      <InfoBox onClick={() => setShowLevels(true)} onClickMarket={() => setShowMarket(true)}/>
      <ImageSlider />
      <NewsGrid />
      <Footer />
      {showLevels && (
        <div className="three-columns-overlay">
          <Levels onHide={() => setShowLevels(false)} />
        </div>
      )}
      {/* Resto de tu aplicación */}
      {showMarket && (
        <div className="three-columns-overlay">
          <MarketPopup onHide={() => setShowMarket(false)} />
        </div>
      )}
    </div>
  );
}

export default HomePage;