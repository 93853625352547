import React, { useState } from 'react';
import '../styles/LoginForm.css';
import { Link, useNavigate } from 'react-router-dom';
import {  signInWithEmailAndPassword  } from 'firebase/auth';
import { auth } from '../firebase';



function LoginForm(){

  const [formText, setFormText] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();


  const handleSubmit = async () => {
    try {
      //Mantenimiento
      setFormText("Estamos realizando labores de mantenimiento en el Área de Inversores con motivo de la incorporación del mercado secundario. Estará de nuevo accesible en las próximas semanas. Lamentamos las molestias.")
      setFormText("")
      const trimmedEmail = email.trim();
      const trimmedPassword = password.trim();
      signInWithEmailAndPassword(auth, trimmedEmail, trimmedPassword)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            navigate('/home');

        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
            setFormText(errorMessage.replace('Firebase:',''))
        });
      // Lógica adicional después de crear el usuario
    } catch (error) {
      console.error('Error al crear el usuario:', error);
      setFormText(error)
    }

  };


  return (
    <div className="login-container">
      <p className="login-title">INICIAR SESIÓN</p>
      <div className="form-group">
        <label className="form-label" htmlFor="email">[E-MAIL]</label>
        <input type="email" id="email"
        className="form-input"
        value={email}
        onChange={(e) => setEmail(e.target.value)}  />
      </div>
      <div className="form-group">
        <label className="form-label" htmlFor="password">[CONTRASEÑA]</label>
        <input type="password" id="password"
        className="form-input"
        value={password}
        onChange={(e) => setPassword(e.target.value)} />
      </div>
      <span className="form-error">{formText}</span><br/>
      <button type="submit" onClick={handleSubmit}>[ENTRAR]</button> <br/>
      <Link className="login-text" to="/signup">¿No tienes una cuenta? Crear cuenta</Link>
    </div>
  );
}

export default LoginForm;
