// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCDTPEEJMTWpTp59Ia99Bh1E9LNN6VdURA",
  authDomain: "banc-backoffice.firebaseapp.com",
  databaseURL: "https://banc-backoffice-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "banc-backoffice",
  storageBucket: "banc-backoffice.appspot.com",
  messagingSenderId: "692453100554",
  appId: "1:692453100554:web:a25ed4fa56b6dd24b463f3",
  measurementId: "G-KJY6FF6TN0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize and get a reference to the service
export const auth = getAuth(app);
export const database = getDatabase(app);
export default app;