import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/ImageSlider.css';
import meit from '../assets/meit-portfolio.png'; 
import crowmie from '../assets/crowmie-portfolio.png'; 
import secondworld from '../assets/secondworld-portfolio.png'; 
import { auth } from '../firebase';
import { getAuth } from "firebase/auth";
import { database } from '../firebase';
import { ref, child, get, set } from "firebase/database";

const ImageSlider = () => {

  const [images, setImages] = useState([])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    className: 'slider',
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: () => <div className="custom-dot" />,
  };


  const dbRef = ref(database);
  get(child(dbRef, "config/slider")).then((snapshot) => {
    //console.log(snapshot.val());

    setImages([snapshot.val()[0], snapshot.val()[1], snapshot.val()[2]])
    
  }).catch((error) => {
    console.log(error)
  })

  

  return (
    <div className="image-slider">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
