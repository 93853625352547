import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import { Link } from 'react-router-dom';
import "../styles/Header.css";
import logo from '../assets/logo-negro-rosa.png'; 
import investorZone from '../assets/investor-zone.png'; 

const HeaderLevels = ({onHide}) => {

  return (
    <>
      <header className="header">
        <img src={logo} alt="Logotipo" className="logo" />
        <img src={investorZone} alt="Imagen central" className="center-image" />
        <FiX className="close" onClick={onHide} />
      </header>
    </>
  );
};

export default HeaderLevels;

