import React, { useState } from 'react';
import '../App.css';
import Footer from '../components/Footer';
import SignUpForm from '../components/SignUpForm';


function SignUpPage() {

  return (
    <div className="page-content">
      <SignUpForm />
      <Footer />
      {/* Resto de tu aplicación */}
    </div>
  );
}

export default SignUpPage;