import React, { useState, useEffect } from 'react';
import '../styles/NewsTable.css';
import { useParams } from 'react-router-dom';
import { database } from '../firebase';
import { ref, child, get } from "firebase/database";
import { FiLink } from "react-icons/fi";

const NewsTable = () => {
  const { id } = useParams();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const dbRef = ref(database, "news");
      try {
        const snapshot = await get(child(dbRef, `/`));
        if (snapshot.exists()) {
          let startupNews = []
          for(let item in snapshot.val()){
            if('' + snapshot.val()[item].startup === id){
              startupNews.push(snapshot.val()[item])
            }
          }
          setItems(startupNews);
        } else {
          console.log("No data available");
          setItems([]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div className="overflow-x">
      <div className="news-columns-row">
        <div className="column"><p className="news-table-header">STARTUP</p></div>
        <div className="column"><p className="news-table-header">TYPE</p></div>
        <div className="column"><p className="news-table-header">DATE</p></div>
        <div className="column"><p className="news-table-header">AUTHOR</p></div>
        <div className="column"></div>
        <div className="column"></div>
      </div>
      <div>
        {items.map((item, index) => (
          <div key={index} className="news-columns-row">
            <div className="column"><img src={item.logo} alt="Logotipo" className="logo" /></div>
            <div className="column"><p className="news-table-cell">{item.type}</p></div>
            <div className="column"><p className="news-table-cell">{item.date}</p></div>
            <div className="column"><p className="news-table-cell">{item.author}</p></div>
            <div className="column"><p className="news-table-text">{item.description}</p></div>
            <div className="column news-table-icon-column">
              <a href={item.link} target="_blank" rel="noopener noreferrer"><FiLink className="news-table-icon" /></a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsTable;
