import React from 'react';
import '../styles/Footer.css';
import { FiInstagram, FiLinkedin } from "react-icons/fi";
import logo from '../assets/logo-blanco-rosa.png'; 
import instagram from '../assets/instagram.png'; 
import linkedin from '../assets/linkedin.png'; 

const FooterPortfolio = () => {
  return (
    <footer className="footer-portfolio">
      <div className="footer-row">
        <div>
          <p className="footer-title-white-portfolio">
            Selecting the best projects for you
            <span className="footer-title-red-bold-portfolio">[investors]</span>
          </p>
        </div>
      </div>
      <div className="footer-row">
        <p className="footer-subtitle-white-portfolio">
          whatever
          <span className="footer-title-red-portfolio">presales@thebanc.io</span>
        </p>
      </div>
      <div className="footer-row">
        <div className="footer-col">
          <p className="footer-text-white-portfolio">Follow us <a href="https://www.instagram.com/thebanc_io/" target="_blank"><img src={instagram} className="footer-icon-white"></img></a><a href="https://es.linkedin.com/company/the-banc" target="_blank"><img src={linkedin} className="footer-icon-white"></img></a>
          </p>
        </div>
        {/*<div className="footer-col footer-text-white-portfolio">
          <p>Legal</p>
        </div>
        <div className="footer-col footer-text-white-portfolio">
          {/*<p>Investment Policy</p>
        </div>*/}
        <div className="footer-col footer-text-white-portfolio footer-logo">
          <img className="logo" src={logo}/>
        </div>
      </div>
    </footer>
  );
};

export default FooterPortfolio;