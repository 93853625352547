import React, { useState } from 'react';
import '../App.css';
import Footer from '../components/Footer';
import LoginForm from '../components/LoginForm';


function LoginPage() {


  return (
    <div className="page-content">
      <LoginForm />
      <Footer />
      {/* Resto de tu aplicación */}
    </div>
  );
}

export default LoginPage;