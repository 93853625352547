import React, { useState } from 'react';
import '../styles/ThreeColumnGrid.css';
import meit from '../assets/meit-portfolio.png'; 
import crowmie from '../assets/crowmie-portfolio.png'; 
import secondworld from '../assets/secondworld-portfolio.png'; 
import dealmate from '../assets/dealmate-portfolio.png'; 
import cirqel from '../assets/cirqel-portfolio.png'; 
import ys from '../assets/ys-portfolio.png'; 
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import { getAuth } from "firebase/auth";
import { database } from '../firebase';
import { ref, child, get, set } from "firebase/database";


const ThreeColumnGrid = () => {

  const [boxes, setBoxes] = useState([])
  const [boxes1, setBoxes1] = useState([])
  const [boxes2, setBoxes2] = useState([])
  const [boxes3, setBoxes3] = useState([])

  const dbRef = ref(database);
  get(child(dbRef, "startups")).then((snapshot) => {
    //console.log(snapshot.val()[0]);

    setBoxes([
      {
        id: 0,
        backgroundImage: `url(${snapshot.val()[0].src})`,
        leftText: snapshot.val()[0].name,
        rightText: snapshot.val()[0].valuation.toLocaleString('es-ES'),
      },
      { 
        id: 1,
        backgroundImage: `url(${snapshot.val()[1].src})`,
        leftText: snapshot.val()[1].name,
        rightText: snapshot.val()[1].valuation.toLocaleString('es-ES'),
      },
      {
        id: 2,
        backgroundImage: `url(${snapshot.val()[2].src})`,
        leftText: snapshot.val()[2].name,
        rightText: snapshot.val()[2].valuation.toLocaleString('es-ES'),
      },
    ])

    setBoxes1([
      {
        id: 3,
        backgroundImage: `url(${snapshot.val()[3].src})`,
        leftText: snapshot.val()[3].name,
        rightText: snapshot.val()[3].valuation.toLocaleString('es-ES'),
      },
      {
        id: 4,
        backgroundImage: `url(${snapshot.val()[4].src})`,
        leftText: snapshot.val()[4].name,
        rightText: snapshot.val()[4].valuation.toLocaleString('es-ES'),
      },
      {
        id: 5,
        backgroundImage: `url(${snapshot.val()[5].src})`,
        leftText: snapshot.val()[5].name,
        rightText: snapshot.val()[5].valuation.toLocaleString('es-ES'),
      },
    ])

    setBoxes2([
      {
        id: 6,
        backgroundImage: `url(${snapshot.val()[6].src})`,
        leftText: snapshot.val()[6].name,
        rightText: snapshot.val()[6].valuation.toLocaleString('es-ES'),
      },
      {
        id: 7,
        backgroundImage: `url(${snapshot.val()[7].src})`,
        leftText: snapshot.val()[7].name,
        rightText: snapshot.val()[7].valuation.toLocaleString('es-ES'),
      },
      {
        id: 8,
        backgroundImage: `url(${snapshot.val()[8].src})`,
        leftText: snapshot.val()[8].name,
        rightText: snapshot.val()[8].valuation.toLocaleString('es-ES'),
      },
    ])

    setBoxes3([
      {
        id: 9,
        backgroundImage: `url(${snapshot.val()[9].src})`,
        leftText: snapshot.val()[9].name,
        rightText: snapshot.val()[9].valuation.toLocaleString('es-ES'),
      },
      {
        id: 10,
        backgroundImage: `url(${snapshot.val()[10].src})`,
        leftText: snapshot.val()[10].name,
        rightText: snapshot.val()[10].valuation.toLocaleString('es-ES'),
      },
      {
        id: 11,
        backgroundImage: `url(${snapshot.val()[11].src})`,
        leftText: snapshot.val()[11].name,
        rightText: snapshot.val()[11].valuation.toLocaleString('es-ES'),
      },
    ])
      
  }).catch((error) => {
    console.error(error);
  });

  

  return (
    <div className="grid-container">
      {boxes.map((box, index) => (
        <Link key={index} to={`/startup/${box.id}`} className="grid-item" style={{ backgroundImage: box.backgroundImage }}>
          <div className="grid-item-bottom">
            <span className="grid-text">{box.leftText}</span>
            <span className="grid-text-red">€{box.rightText}</span>
          </div>
        </Link>
        
      ))}
      {boxes1.map((box, index) => (
        <Link key={index} to={`/startup/${box.id}`} className="grid-item" style={{ backgroundImage: box.backgroundImage }}>
          <div className="grid-item-bottom">
            <span className="grid-text">{box.leftText}</span>
            <span className="grid-text-red">€{box.rightText}</span>
          </div>
        </Link>
      ))}
      {boxes2.map((box, index) => (
        <Link key={index} to={`/startup/${box.id}`} className="grid-item" style={{ backgroundImage: box.backgroundImage }}>
          <div className="grid-item-bottom">
            <span className="grid-text">{box.leftText}</span>
            <span className="grid-text-red">€{box.rightText}</span>
          </div>
        </Link>
      ))}
      {boxes3.map((box, index) => (
        <Link key={index} to={`/startup/${box.id}`} className="grid-item" style={{ backgroundImage: box.backgroundImage }}>
          <div className="grid-item-bottom">
            <span className="grid-text">{box.leftText}</span>
            <span className="grid-text-red">€{box.rightText}</span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ThreeColumnGrid;
