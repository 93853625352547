import React, { useState } from 'react';
import { FiMapPin, FiInstagram, FiLinkedin, FiGlobe } from "react-icons/fi";
import { Link, useParams } from 'react-router-dom';
import { auth } from '../firebase';
import { getAuth } from "firebase/auth";
import { database } from '../firebase';
import { ref, child, get, set } from "firebase/database";
import { FiChevronLeft } from "react-icons/fi";
import '../styles/MarketActions.css';


function MarketActions({onClick}) {

  return (
    <>
      <div className="container-market">
        <button className="button-buy" onClick={onClick}>Comprar</button>
        <button className="button-sell" onClick={onClick}>Vender</button>
      </div>

    </>
  );
}

export default MarketActions;