import React, { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link, useNavigate } from 'react-router-dom';
import "../styles/Header.css";
import "../styles/Footer.css";
import burger from '../assets/burger-menu.png'; 
import instagram from '../assets/instagram.png'; 
import linkedin from '../assets/linkedin.png'; 
import logo from '../assets/logo-negro-rosa.png'; 
import logoWhite from '../assets/logo-blanco-rosa.png'; 
import investorZone from '../assets/investor-zone.png';
import investorZoneWhite from '../assets/investor-zone-blanco.png'; 
import { FiInstagram, FiLinkedin } from "react-icons/fi";
import { auth } from '../firebase';
import {  signOut } from "firebase/auth";


const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [logoSrc, setLogoSrc] = useState(logo);
  const [investorZoneSrc, setInvestorZoneSrc] = useState(investorZone);


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setLogoSrc(logoWhite);
    setInvestorZoneSrc(investorZoneWhite)
  };

  const navigate = useNavigate();

  const handleLogout = () => {               
      signOut(auth).then(() => {
      // Sign-out successful.
          navigate("/");
          setMenuOpen(false)
          setLogoSrc(logo);
          setInvestorZoneSrc(investorZone)
          console.log("Signed out successfully")
      }).catch((error) => {
        console.log("Error")
      });
  }

  return (
    <>
      <header className="header">
        <a className="logo-a" href="https://thebanc.io"><img src={logoSrc} alt="Logotipo" className="logo" /></a>
        <Link className="center-image-a" to="/home"><img src={investorZoneSrc} alt="Imagen central" className="center-image" /></Link>
        <img src={burger} className="hamburger" onClick={toggleMenu} />
      </header>
      {menuOpen && (
        <div className="fullscreen-menu">
          <nav>
            <FiX className="close close-icon" onClick={() => {setMenuOpen(false); setLogoSrc(logo);setInvestorZoneSrc(investorZone)}} />
            <ul>
              <div className="menu-line"></div>
              <li className="menu-item">
                <Link onClick={() => {setMenuOpen(false);setLogoSrc(logo);setInvestorZoneSrc(investorZone)}} to="/home">Inicio</Link>
              </li>
              <div className="menu-line"></div>
              <li className="menu-item">
                <Link onClick={() => {setMenuOpen(false);setLogoSrc(logo);setInvestorZoneSrc(investorZone)}} to="/portfolio">Startups</Link>
              </li>
              <div className="menu-line"></div>
            </ul>

            <div className="menu-footer-row">
              <p className="footer-title-red cursor-pointer" onClick={handleLogout}>[Log Out]</p>
              <p className="footer-text-white">Follow us <a href="https://www.instagram.com/thebanc_io/" target="_blank"><img src={instagram} className="footer-icon-white"></img></a><a href="https://es.linkedin.com/company/the-banc" target="_blank"><img src={linkedin} className="footer-icon-white"></img></a></p>
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

export default Header;

